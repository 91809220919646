import { Alert, Box, InputLabel, Typography } from '@mui/material'
import React, { useState} from 'react'
import { Link } from 'react-router-dom';
import {  Card, Form } from '../../../styles/forms';
import TextArea from '../../../Components/FormFields/TextArea';
import Primary from '../../../Components/Button/Primary';
import Text from '../../../Components/Button/Text';
import Disabled from '../../../Components/Button/Disabled';




const LoginCard = () => {

  const [valid_email,setValid_email]=useState(false);
  const [valid_password,setValid_password]=useState(false);
  const [input_data,setInput_data]=useState({
    email:"",
    password:"",
  })
  const handleChange=(e)=>{
    setInput_data({...input_data ,[e.target.name]:e.target.value})
  }
  const handleSubmit=(e)=>{
    e.preventDefault();
    
    if(input_data.email.length===0){
      setValid_email(true);  
    }
    else if(input_data.password.length<6){
      setValid_password(true);
    }
      console.log(input_data ) 
    
  }
 
 
  
  return (
    <>
    <Card>
      <Box width={'100%'}>
        <Typography fontSize='1.285rem' fontWeight= '600' marginBottom={1}>Welcome to A2D eats</Typography>
        <Typography fontSize='0.9rem' color='#6e6b7b'>Please sign-in to your account and start the adventure</Typography>
        </Box> 
      <Form onSubmit={(e)=>handleSubmit(e)}>
          <InputLabel sx={{fontSize:'0.9rem',marginBottom:0.5}}>Email</InputLabel>
          <TextArea sx={{marginBottom:2}} type="text" name="email" placeholder='Enter Email Address' value={input_data.email} onChange={handleChange}/>
          {
       valid_email && <Alert sx={{marginBottom:2}} severity="error">Enter your email address !!</Alert>
      }
          <InputLabel sx={{fontSize:'0.9rem',marginBottom:0.5}}>Password</InputLabel>
          <Box>
          <TextArea type="password" sx={{marginBottom:0.5}} name="password" placeholder='Enter password' value={input_data.password} onChange={handleChange}/>
          {
       valid_password && <Alert sx={{marginBottom:2}} severity="error">Password must contain 6 characters !!</Alert>
      }
         
          </Box>
          <Box textAlign={'end'}>
           <Link to={'/forgot_password'} style={{textDecoration:'none'}}>
            
            <Text sx={{ marginBottom:'1rem'}} value="Forgot Password ?"/>
           </Link> 
          </Box>
          {
            (input_data.email && input_data.password) ?
              <Primary type='submit' value="Submit"/> 
              :
              <Disabled value="Submit" />
          }

      </Form>
     
     
    </Card>
    </>
  )
}

export default LoginCard
