import { TextField } from '@mui/material';
import React from 'react'
import styled from 'styled-components';


const StyledTextField = styled(TextField)(({ theme }) => ({
 width:'100%',
  '& .MuiOutlinedInput-root': {  
      borderRadius: '6px',
      backgroundColor: 'transparent', 
      width:'100%',
      height:'2.4rem',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFA943', 
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFA943', // Set border color when focused
        border:'1px solid #FFA943',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      },
    },
    '& .MuiInputBase-input': {
      fontSize:'14px',
      padding: '10px 2px', 
      color: 'rgb(47 43 61 / 68%)', 
    },
  }));
  
const TextArea = (props) => {
    const {value,onChange,type,name,placeholder,sx}=props;
   
  return (
    <form noValidate autoComplete='off' className='demo-space-x'>
      <StyledTextField
        multiline
        maxRows={4}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        aria-readonly
        type={type}
        sx={sx}
        id='textarea-outlined-controlled'
      />
      </form>
  )
}

export default TextArea
