import React from 'react'
import { Box } from '@mui/material'
import LoginCard from './LoginCard'
import { ImageBox, Login__Container, StyledBox, StyledMainBox } from '../../../styles/forms';
import { Eats } from '../../../utils/Eats';
import { Image } from '../../../utils/Background';


const Login = () => {
  return (
   // eslint-disable-next-line react/jsx-pascal-case
   <Login__Container style={{ position: 'relative' }}>

    {/* for heading section  */}
        <ImageBox  textAlign= "center" >
            <Eats />
        </ImageBox>
        {/* for other section  */}
        <StyledMainBox style={{ position: 'relative' }}>
            {/* background design  */}
            <StyledBox style={{ position: 'absolute', top: -70, left: -70, zIndex: -1 }}>
               <Image />
            </StyledBox>

            {/* for login form */}
            <Box style={{ position: 'relative', zIndex: 1,background:'white' }}>
                <LoginCard />
            </Box>
        </StyledMainBox>
   </Login__Container>
  )
}

export default Login
