import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import {  Card } from "../../../styles/forms"
import { Mail } from "../../../utils/Mail"
import Primary from "../../../Components/Button/Primary"




const ConfirmCard = () => {

  
  
  return (
    <>
    <Card>
        
        <Mail />
      <Box width={'100%'} marginTop={2} textAlign={'center'}>
        <Typography fontSize='1.287rem' color="#5e5873" marginBottom="35px" lineHeight="1" fontWeight= '600' >Please check your email to set a new password</Typography>
        {/* <Typography fontSize='1.287rem' fontWeight= '600' >new password</Typography> */}
        <Link to={"/"} style={{textDecoration:'none'}} >
          <Primary sx={{opacity:1,marginTop:'20px'}} width="100%" value="Continue"/>
         </Link>
        </Box> 
    
     
     
    </Card>
    </>
  )
}

export default ConfirmCard
