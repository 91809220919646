import { Box, InputLabel, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Card, Form, StyledButton } from '../../../styles/forms';
import TextArea from '../../../Components/FormFields/TextArea';
import Primary from '../../../Components/Button/Primary';
import Disabled from '../../../Components/Button/Disabled';




const PasswordCard = () => {
 
  
  const [input_data,setInput_data]=useState({
    email:"",
  })
  const handleChange=(e)=>{
    setInput_data({...input_data ,[e.target.name]:e.target.value})
  }
  const handleSubmit=(e)=>{
    e.preventDefault();
      console.log(input_data ) 
  }
 
  
  return (
    <>
    <Card>
      <Box width={'100%'}>
        <Typography fontSize='1.285rem' textAlign={'center'} fontWeight= '600' marginBottom={1}>Forgot Password ?</Typography>

        </Box> 
      <Form onSubmit={(e)=>handleSubmit(e)}>
          <InputLabel sx={{fontSize:'0.9rem',marginBottom:0.5}}>Email</InputLabel>
          <TextArea sx={{marginBottom:2}} type="text" name="email" placeholder='Enter Email Address' value={input_data.email} onChange={handleChange}/>
        {
          input_data.email ? 
         <Link to={"/confirm"} style={{textDecoration:'none'}}>
          <Primary type='submit' value="Continue" width='100%'/>
         </Link>
         :
         <Disabled value="Continue" />
        }
          <Box>
            <StyledButton sx={{marginTop:'1.2rem', marginBottom:'1.5rem',marginRight:"3px",fontWeight:500,fontSize:'0.9rem'}} >Remember Password?
            <Link to={'/'} style={{color:'rgb(255, 52, 43)',textDecoration:'none'}}>Back to Login </Link>
            </StyledButton>
           
          </Box>

      </Form>
     
     
    </Card>
    </>
  )
}


export default PasswordCard
