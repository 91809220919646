import React from "react";

import {  Button } from "@mui/material";

const Disabled = (props) => {
  const {type,value,width,height}=props;

  return (
    // <Box >
      <Button variant="contained"
      type={type}
    
      sx={{ 
        textDecoration: 'none',
        width:width,
        height:height,
        lineHeight: '1.2',
        fontSize: '0.9375rem',
        letterSpacing: '0.43px',
        borderRadius:'6px',
        // color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgb(252,151,125)',
        minWidth: '50px',
        textTransform: 'none',
        boxShadow: 'rgba(47, 43, 61, 0.14) 0px 2px 6px 0px',
        padding: '0.625rem 1.25rem',
        '&:focus':{
            backgroundColor: 'rgb(252,151,125)',
        },
        '&:hover':{
          boxShadow:'none',
          backgroundColor: 'rgb(252,151,125)',
        },
        
      }}
      >{value}</Button>
    // </Box>
  );
};

export default Disabled;
