import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './auth/forms/login/Login'
import Password from './auth/forms/forgetPassword/Password'
import Confirm from './auth/forms/confimation/Confirm'



const App = () => {
  return (
    <Routes>
      
      {/* Components  */}

      <Route path='/' element={<Login />}/>
      <Route path='/forgot_password' element={<Password />}/>
     <Route path='/confirm' element={<Confirm />}/>
    
      
    

    </Routes>
  )
}

export default App
