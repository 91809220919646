import { Box } from "@mui/material"
import ConfirmCard from "./ConfirmCard.jsx"
import { ImageBoxPassword, Login__Container, StyledBoxConfirm, StyledMainBoxConfirm } from "../../../styles/forms.js"
import { Eats } from "../../../utils/Eats.js"
import { Image } from "../../../utils/Background.js"



const Confirm= () => {
  return (
   // eslint-disable-next-line react/jsx-pascal-case
   <Login__Container style={{ position: 'relative' }}>

    {/* for heading section  */}
        <ImageBoxPassword textAlign= "center">
            <Eats />
        </ImageBoxPassword>
        {/* for other section  */}
        <StyledMainBoxConfirm  style={{ position: 'relative' }}>
            {/* background design  */}
            <StyledBoxConfirm style={{ position: 'absolute', top: -70, left: -70, zIndex: -1 }}>
               <Image />
            </StyledBoxConfirm>

            {/* for login form */}
            <Box style={{ position: 'relative', zIndex: 1,background:'white' }}>
                <ConfirmCard />
            </Box>
        </StyledMainBoxConfirm>
   </Login__Container>
  )
}

export default Confirm
