import React from "react";
import { Box } from "@mui/material";
import PasswordCard from "./PasswordCard.jsx";
import {  ImageBoxPassword, Login__Container, StyledBoxPassword, StyledMainBoxPassword } from "../../../styles/forms.js";
import { Eats } from "../../../utils/Eats.js";
import { Image } from "../../../utils/Background.js";


const Password = () => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Login__Container style={{ position: "relative" }}>
      {/* for heading section  */}
      <ImageBoxPassword textAlign="center" >
        <Eats />
      </ImageBoxPassword>
      {/* for other section  */}
      <StyledMainBoxPassword style={{ position: "relative" }}>
        {/* background design  */}
        <StyledBoxPassword style={{ position: "absolute", top: -70, left: -70, zIndex: -1 }}>
          <Image/>
        </StyledBoxPassword>

        {/* for login form */}
        <Box style={{ position: "relative", zIndex: 1, background: "white" }}>
          <PasswordCard />
        </Box>
      </StyledMainBoxPassword>
    </Login__Container>
  );
};

export default Password;
