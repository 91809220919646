import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledText = styled(Button)({
  lineHeight: "1.2",
  fontSize: "12px !important",
  letterSpacing: "0.43px",
  borderRadius: "6px",
  color: "rgb(255, 52, 43) !important",
  minWidth: "50px",
  textDecoration:'none !important',
  textTransform:'none !important',
  transition:
    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  padding: "0.625rem 0.75rem",
  "&:hover": {
    boxShadow: "none !important",
  },
  '@media (max-width: 600px)': {
    textDecoration: "none !important", // Override text decoration for mobile devices
  }
});
const Text = (props) => {
  const { value, handleClick, sx } = props;
  return (
    //  text button component

    <StyledText sx={sx} variant="text" onClick={handleClick}>
      {value}
    </StyledText>
  );
};

export default Text;
