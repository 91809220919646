import React from "react";

import {  Button } from "@mui/material";

const Primary = (props) => {
  const {type,value,handleClick,width,height}=props;

  return (
    // <Box >
      <Button variant="contained"
      type={type}
      
      onClick={handleClick}
      sx={{ 
        textDecoration: 'none',
        width:width,
        height:height,
        lineHeight: '1.2',
        fontSize: '0.9375rem',
        letterSpacing: '0.43px',
        borderRadius:'6px',
        color: 'rgb(255, 255, 255)',
        background: 'linear-gradient(166.45deg, rgb(242, 71, 63) -14.38%, rgb(255, 107, 52) 105%)',
        minWidth: '50px',
        textTransform: 'none',
        boxShadow: 'rgba(47, 43, 61, 0.14) 0px 2px 6px 0px',
        padding: '0.625rem 1.25rem',
        '&:hover':{
          boxShadow:'none',
        },
        
      }}
      >{value}</Button>
    // </Box>
  );
};

export default Primary;
