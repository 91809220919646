import { Box, ButtonBase, InputBase } from "@mui/material";
import styled from "styled-components";

export const Login__Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90vh',
})

export const Card = styled(Box)({
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    padding: 20,
    display: 'flex',
    border: '0 solid rgba(34,41,47,.125)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '400px',
})
export const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 20,

})
export const Input = styled(InputBase)`
    border:1px solid #d8d6de;
    padding:2px 10px;
    border-radius: .357rem;
    width:100%;
    input::placeholder{
        font-size:14px;
       
    }
    
`
export const Button=styled(ButtonBase)({
    width:'100%',
    border: '1px solid transparent !important',
    padding: '.786rem 1.5rem !important',
    borderRadius: '  !important',
    backgroundColor:'#f2473f !important',
    color:'white !important',
    opacity:0.65,
    fontWeight:600,
    fontSize:'1rem',
    height:35,
})
export const StyledBox = styled(Box)`
  @media (max-width: 600px) {
    display: none !important;
  } 
`;
export const StyledMainBox=styled(Box)`
width:25rem;
@media (max-width: 480px) {
    width:20rem;
  }
  @media (max-width: 350px) {
    width:15rem;
  }
`
export const ImageBox=styled(Box)`
padding-bottom:120px;
padding-top:20px;
@media (max-width: 480px) {
    padding-bottom:80px;
    padding-top:10px;
  }
`
export const ImageBoxPassword=styled(Box)`
padding-bottom: 70px;
padding-top: 0px;
margin-bottom: 70px;
@media (max-width: 480px) {
    padding-bottom:70px;
    padding-top:0px;
    margin-bottom: 70px;
  }
`
export const StyledButton=styled(ButtonBase)`
@media (max-width: 350px) {
    flex-direction:column;
    align-items:flex-start !important;
    &>a {
      margin-top:5px;
    }
}
`

export const StyledBoxPassword = styled(Box)`
  @media (max-width: 600px) {
    display: none !important;
  }
`;
export const StyledMainBoxPassword=styled(Box)`
width:20rem;
@media (max-width: 480px) {
    width:20rem;
  }
  @media (max-width: 350px) {
    width:15rem;
  }
`
export const StyledMainBoxConfirm=styled(Box)`
width:25rem;
@media (max-width: 480px) {
    width:20rem;
  }
  @media (max-width: 350px) {
    width:15rem;
  }
`
export const StyledBoxConfirm = styled(Box)`
  @media (max-width: 600px) {
    display: none !important;
  } 
`;